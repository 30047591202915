<template>
  <div class="service">
    <div>
      <!-- tab -->
      <van-sticky>
        <van-tabs
          v-model="active"
          color="#25262b"
          background="#ffc200"
          sticky
          @click="tabsClick"
        >
          <van-tab title="服务项目"></van-tab>
          <van-tab title="服务分类"></van-tab>
        </van-tabs>
      </van-sticky>
      <div v-if="active == 0">
        <van-button
          type="primary"
          @click="urlPost('add', '2')"
          color="#ffc200"
          block
          class="addON"
          ><van-icon name="plus" color="#000" />新增服务项目</van-button
        >
        <div
          class="serviceBox clearfix"
          v-for="item in ServicetOfStaList"
          :key="item.index"
        >
          <div style="float: left">
            <img
              :src="item.ImgUrl"
              alt=""
              style="width: 105px; height: 105px; margin: 7px"
            />
          </div>
          <div class="service_title clearfix" style="float: right; width: 53%">
            {{ item.Name }}
            <span style="float: right; font-size: 14px; color: red"
              >{{ item.Amount }}{{ item.MUnit }}</span
            >
          </div>
          <div class="service_info">
            服务类型 : <span>{{ item.SerKindDesc }}</span>
          </div>
          <div class="service_info">
            服务分类 : <span>{{ item.SSName }}</span>
          </div>
          <div class="service_info" v-if="item.Describe">
            <span>{{ item.Describe }}</span>
          </div>
          <div style="margin: 10px 25px; float: right">
            <van-button
              type="info"
              size="small"
              class="service_on"
              @click="urlPost(item, '2')"
              >编辑</van-button
            >
            <van-button
              type="danger"
              size="small"
              style="margin-left: 15px"
              class="service_on"
              @click="deleteB(item.SId)"
              >删除</van-button
            >
          </div>
        </div>
      </div>
      <div v-if="active == 1">
        <van-button
          type="primary"
          @click="urlPost('add', '1')"
          color="#ffc200"
          block
          class="addON"
          ><van-icon name="plus" color="#000" />新增服务分类</van-button
        >
        <div
          class="serviceBox clearfix"
          v-for="item in ServiceSortList"
          :key="item.index"
        >
          <!-- <div style="float: left">
            <img
              :src="item.Img"
              style="width: 105px; height: 105px; margin: 7px"
            />
          </div> -->
          <div class="service_title clearfix" style="float: left; width: 53%">
            {{ item.Name }}
          </div>
          <div class="service_info" style="float: left; width: 100%">
            服务站 : <span>{{ item.StationName }}</span>
          </div>
          <div class="service_info" style="float: left; width: 100%">
            小 区 : <span>{{ item.RName }}</span>
          </div>
          <div class="service_info" style="float: left; width: 100%">
            服务类型 : <span>{{ item.SerKindDesc }}</span>
          </div>
          <div
            class="service_info"
            v-if="item.Describe"
            style="float: left; width: 100%"
          >
            服务描述 : <span>{{ item.Describe }}</span>
          </div>

          <div style="margin: 10px 14px; float: left">
            <van-button
              type="info"
              size="small"
              class="service_on"
              @click="urlPost(item, '1')"
              >编辑</van-button
            >
            <van-button
              type="danger"
              size="small"
              style="margin-left: 15px"
              class="service_on"
              @click="deleteA(item.SSId)"
              >删除</van-button
            >
          </div>
        </div>
      </div>
      <!-- <van-tab title="机构管理">
        <div class="serviceBox clearfix">
          <div class="service_title">机 构 照 片</div>
          <div
            style="width: 75px; height: 75px; float: right; margin: 12px 15px"
          >
            <img
              src="https://lycaljdapi.dexian.ren//upload/images/2021/12/6fc2966a-108.png"
              alt=""
              style="width: 100%"
            />
          </div>
        </div>
        <div class="serviceBox clearfix">
          <div
            class="service_title clearfix"
            style="float: none; padding-top: 10px"
          >
            门 店 信 息
          </div>
          <div class="service_BG clearfix">
            <div class="clearfix">
              <span style="float: left">基础信息</span
              > -->
      <!-- <span style="float: right; font-size: 13px; color: #6cb9f5">编辑 ></span> -->
      <!--</div>
            <div
              class="clearfix"
              style="font-size: 10px; margin-top: 5px; color: #aaa"
            >
              管理机构营业时间、电话和地址等信息
            </div>
          </div>
          <div class="service_info">
            机 构 分 类: <span>长一社区养老局服务站</span>
          </div>
          <div class="service_info">营 业 状 态: <span>0736-4321077</span></div>
          <div class="service_info">营 业 状 态: <span>营业中</span></div>
          <div class="service_info" style="padding-bottom: 15px">
            营 业 时 间: <span>24小时</span>
          </div>
        </div>
        <div class="serviceBox clearfix">
          <div class="service_title clearfix" style="width: 100%">
            服 务 菜 单
          </div>
          <div class="clearfix">
            <van-swipe :loop="false" :width="300">
              <van-swipe-item>
                <div
                  style="
                    width: 80px;
                    height: 80px;
                    float: left;
                    margin: 12px 15px;
                  "
                >
                  <img
                    src="https://lycaljdapi.dexian.ren//upload/images/2021/12/6fc2966a-108.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                <div style="float: left">
                  <div style="margin: 10px 0">内容</div>
                  <div style="margin: 10px 0">内容</div>
                  <div style="margin: 10px 0">内容</div>
                </div>
              </van-swipe-item>
              <van-swipe-item
                ><div
                  style="
                    width: 80px;
                    height: 80px;
                    float: left;
                    margin: 12px 15px;
                  "
                >
                  <img
                    src="https://lycaljdapi.dexian.ren//upload/images/2021/12/6fc2966a-108.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                <div style="float: left">
                  <div style="margin: 10px 0">内容</div>
                  <div style="margin: 10px 0">内容</div>
                  <div style="margin: 10px 0">内容</div>
                </div></van-swipe-item
              >
              <van-swipe-item
                ><div
                  style="
                    width: 80px;
                    height: 80px;
                    float: left;
                    margin: 12px 15px;
                  "
                >
                  <img
                    src="https://lycaljdapi.dexian.ren//upload/images/2021/12/6fc2966a-108.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                <div style="float: left">
                  <div style="margin: 10px 0">内容</div>
                  <div style="margin: 10px 0">内容</div>
                  <div style="margin: 10px 0">内容</div>
                </div></van-swipe-item
              >
              <van-swipe-item
                ><div
                  style="
                    width: 80px;
                    height: 80px;
                    float: left;
                    margin: 12px 15px;
                  "
                >
                  <img
                    src="https://lycaljdapi.dexian.ren//upload/images/2021/12/6fc2966a-108.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                <div style="float: left">
                  <div style="margin: 10px 0">内容</div>
                  <div style="margin: 10px 0">内容</div>
                  <div style="margin: 10px 0">内容</div>
                </div></van-swipe-item
              >
            </van-swipe>
          </div>
        </div>
      </van-tab> -->
      <template>
        <div>
          <van-tabbar v-model="tabbar" active-color="#ffc200">
            <van-tabbar-item icon="column" to="/CalYl/"
              >订单管理</van-tabbar-item
            >
            <van-tabbar-item icon="goods-collect" to="/CalYl/service"
              >服务管理</van-tabbar-item
            >
            <van-tabbar-item icon="bookmark" @click="jumpUrl('/CalYl/history')"
              >套餐服务</van-tabbar-item
            >
            <van-tabbar-item icon="manager" @click="jumpUrl('/CalYl/prompt')"
              >个人中心</van-tabbar-item
            >
          </van-tabbar>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Dialog);
Vue.use(Toast);
import { getToken, setToken } from "@/utils/auth";
import {
  WeGetStaServiceSortPage,
  WeGetProServicetOfStaPage,
  WeDeleteProService,
  WeDeleteStaServiceSort,
} from "@/api/Lycal.js";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      tabbar: 1,
      active: 0,
      filters: {
        page: 1,
        limit: 999,
      },
      acctoken: "",
      // 2f3857384b395c387d667c6657667d6352632f346d3630342f3435625c642f3635615c31303435376d3635382f6370652f37353070322f395c3947642f34573351382f384b3951385c665c66df85
      ServiceSortList: {},
      ServicetOfStaList: {},
    };
  },
  mounted() {
    this.acctoken = getToken();
    this.getlist();
  },
  methods: {
    tabsClick(e) {
      this.getlist();
      scrollTo(0, 0);
    },
    // 跳转
    jumpUrl(val) {
      this.$router.push({
        name: val,
      });
    },
    // 编辑跳转
    urlPost(data, val) {
      if (data != "add") {
        data.type = val;
        var dataFrom = JSON.stringify(data);
        this.$router.push({
          name: "/CalYl/service/editor/",
          query: {
            id: dataFrom,
          },
        });
      } else {
        var dataFrom = {};
        dataFrom.type = val;
        dataFrom = JSON.stringify(dataFrom);
        this.$router.push({
          name: "/CalYl/service/editor/",
          query: {
            id: dataFrom,
          },
        });
      }
    },
    // 获取分页
    getlist() {
      this.filters.accToken = this.acctoken;
      WeGetStaServiceSortPage(this.filters).then((res) => {
        this.ServiceSortList = res.data.data;
      });
      WeGetProServicetOfStaPage(this.filters).then((val) => {
        this.ServicetOfStaList = val.data.data;
      });
    },
    // 删除1
    deleteA(val) {
      console.log(val);
      Dialog.confirm({
        title: "提示",
        message: "是否删除服务项目!",
      })
        .then(() => {
          console.log(2);
          WeDeleteStaServiceSort({ accToken: this.acctoken, SSId: val }).then(
            (res) => {
              console.log(res);
              if (res.data.code == 0) {
                Toast.success("删除成功");
                this.getlist();
              } else {
                Toast.fail(res.data.msg);
              }
            }
          );
        })
        .catch(() => {
          console.log("错误");

          // on cancel
        });
    },
    // 删除2
    deleteB(val) {
      Dialog.confirm({
        title: "提示",
        message: "是否删除服务项目!",
      })
        .then(() => {
          WeDeleteProService({ accToken: this.acctoken, SId: val }).then(
            (res) => {
              console.log(res);
              if (res.data.code == 0) {
                Toast.success("删除成功");
                this.getlist();
              } else {
                Toast.fail(res.data.msg);
              }
            }
          );
        })
        .catch(() => {
          console.log("错误");
          // on cancel
        });
    },
  },
};
</script>
<style scoped>
.service {
  background: #f7f7f9;
  padding-bottom: 60px;
}
.serviceBox {
  width: 90%;
  margin: 10px 5%;
  background: #fff;
  border-radius: 12px;
}
.service_title {
  float: left;
  margin: 10px 15px;
  font-size: 16px;
  font-weight: 700;
}
.service_info {
  font-size: 16px;
  margin: 5px 15px;
  color: #000;
  width: 53%;
  float: right;
}
.service_BG {
  display: block;
  margin: 5px 15px;
}
.service_info span {
  font-size: 14px;
  color: #333;
}
.serviceBox .van-swipe {
  width: 100%;
}
.service_on span {
  padding: 5px 14px;
}
.addON {
  width: 90%;
  margin: 10px 5%;
  border-radius: 8px;
}
.addON span {
  color: #000;
}
</style>